<template>
  <main class="flex w-full flex-col">
    <AppHeader />
    <div class="flex min-h-0 grow flex-col md:flex-row">
      <AppSidebar class="shrink-0" />
      <section class="grow overflow-y-auto scroll-smooth">
        <slot />
      </section>
    </div>
  </main>
</template>

<style lang="postcss">
*:focus-visible {
  @apply outline-gray-800 outline-1 outline -outline-offset-1;
}
</style>
