<script lang="ts" setup>
import dayjs from 'dayjs'
import LineAlertCircle from '@docue/docue-ui-v2/icons/LineAlertCircle.vue'

const props = defineProps<{
  graceStartsAt: string
  graceEndsAt: string
}>()

const { t } = useI18n()
const { formatRelativeTime } = useIntl()
const now = useNow({ interval: 60_000 })
const { subscribe: subscribeRoutePath } = useLinks()

const amountOfDaysLeft = computed(() => dayjs(props.graceEndsAt).diff(now.value, 'day'))

const gracePeriodProgress = computed(() => {
  const gracePeriodLengthInDays = dayjs(props.graceEndsAt).diff(props.graceStartsAt, 'day')
  return amountOfDaysLeft.value / gracePeriodLengthInDays * 100
})

const relativeTimeLeft = computed(() => formatRelativeTime(amountOfDaysLeft.value, 'day', { numeric: 'always' }))
</script>

<template>
  <div
    class="
        relative
        flex
        flex-col
        items-center
        gap-3
        overflow-hidden
        rounded-lg
        bg-gray-50
        p-3
        after:absolute
        after:inset-x-0
        after:top-0
        after:h-1
        after:w-full
        after:bg-gray-200
        "
  >
    <div
      class="absolute left-0 top-0 z-10 h-1 rounded-r-full bg-blue-600"
      :style="{ width: `${gracePeriodProgress}%` }"
    />
    <LineAlertCircle class="mt-2 inline-block size-5" />
    <p class="text-center text-xs">
      {{ t('gracePeriod.accessEndsIn', { relativeTimeLeft }) }}
    </p>
    <DTButton
      variant="blue"
      size="xs"
      class="w-full"
      :to="subscribeRoutePath()"
      external
    >
      {{ capitalize(t('gracePeriod.resubscribe')) }}
    </DTButton>
  </div>
</template>
