<script lang="ts" setup>
import sysend from 'sysend'
import { onClickOutside, useMagicKeys } from '@vueuse/core'
import LineSearchMd from '@docue/docue-ui-v2/icons/LineSearchMd.vue'
import type { Document, Folder } from '~~/types'
import type { ToastVariant } from '~~/types/ui'

const { t } = useI18n()
const { workspace } = useAuth()

const isOpen = ref(false)
const dialog = ref<HTMLDivElement>()
const height = ref(0)
const isMac = /Mac|iPhone|iPod|iPad/i.test(navigator.platform)

const openSearch = () => {
  isOpen.value = true
  sysend.broadcast('search::opened')
}

const closeSearch = () => {
  isOpen.value = false
  sysend.broadcast('search::closed')
}

const keys = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.metaKey && e.key === 'k' && e.type === 'keydown')
      e.preventDefault()
  },
})

const MetaK = keys['Meta+K']
const CtrlK = keys['Ctrl+K']

watch([MetaK, CtrlK], (values) => {
  if (values.includes(true))
    openSearch()
})

onClickOutside(dialog, closeSearch)

onMounted(() => {
  sysend.on('search::close', () => {
    closeSearch()
    nextTick(() => window.focus())
  })
  sysend.on('search::navigate', (n: { path: string, target?: string }) => {
    isOpen.value = false
    if (n.target)
      window.open(n.path, n.target)
    else
      navigateTo(n.path)
  })
  sysend.on('search::select', async (id: string) => {
    useDocumentsItemSelection().value = await useApi().fetchDocument(id)().then(r => r.data)
  })
  sysend.on('search::height', (h: number) => {
    height.value = h
  })
  sysend.on('search::error', () => {
    useToast().danger(t('search.delete-error'))
  })
  sysend.on('search::deleted', (rstr: string) => {
    const r = JSON.parse(rstr) as Folder | Document
    if (r._type === 'document')
      onDocumentDeleted(r.id)
    else if (r._type === 'folder')
      onFolderDeleted(r)
  })
  sysend.on('search::toast', (toast: { content: string, variant?: ToastVariant }) => {
    const { content, variant = 'green' } = toast
    useToast().add({ content, variant })
  })
})

onBeforeUnmount(() => {
  sysend.off('search::close')
  sysend.off('search::navigate')
  sysend.off('search::select')
  sysend.off('search::height')
  sysend.off('search::error')
  sysend.off('search::deleted')
  sysend.off('search::toast')
})
</script>

<template>
  <div
    v-if="!!workspace?.attributes.root_folder_id"
    class="flex w-full flex-1 shrink-0"
  >
    <div
      :class="isOpen
        ? 'absolute inset-0 h-full bg-gray-800/40 md:bg-transparent'
        : ''
      "
      class="z-20 mx-auto flex w-full justify-end md:relative md:h-auto md:w-4/5 lg:w-3/5"
    >
      <DTIconButton
        v-if="!isOpen"
        variant="plain"
        class="-mr-2 md:hidden"
        @click="openSearch"
      >
        <LineSearchMd class="size-5 text-gray-600" />
      </DTIconButton>
      <button
        class="
            hidden
            w-full
            items-center
            justify-start
            gap-2
            rounded-xl
            border
            border-transparent
            bg-gray-100
            px-3
            py-2
            hover:border-gray-200
            md:flex
            "
        :class="{ 'cursor-wait': isOpen && height === 0 }"
        @click="openSearch"
      >
        <LineSearchMd class="size-5 text-gray-600" />
        <span class="text-base text-gray-400">{{ $t('search.placeholder') }}</span>
        <span
          class="
              ml-auto
              mr-0
              rounded-md
              border
              border-black/10
              bg-gray-50
              px-2
              py-1
              text-xs
              text-gray-800
              "
        >
          <kbd
            v-if="isMac"
            class="font-sans"
          >⌘</kbd>
          <kbd
            v-else
            class="font-sans"
          >Ctrl+</kbd>
          <kbd class="font-sans">K</kbd>
        </span>
      </button>
      <div
        v-show="isOpen && height > 0"
        ref="dialog"
        class="
            absolute
            inset-x-0
            top-2
            z-50
            max-h-[520px]
            overflow-hidden
            rounded-xl
            border-gray-200
            bg-white
            shadow-lg
            md:top-[-4px]
            md:border
            "
        :style="`height:${height}px;`"
      >
        <iframe
          class="size-full bg-transparent"
          src="/2/search?is-child-app=true"
        />
      </div>
    </div>
  </div>
</template>
